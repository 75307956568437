<template>
  <v-card
    class="mx-auto"
    max-width="800"
    outlined
    style="margin: 50px 50px 50px 50px"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">File Upload</div>
        <v-list-item-title class="text-h5 mb-1">
          Invoice Upload
        </v-list-item-title>
        <v-file-input
          label="Invoice"
          outlined
          dense
          v-model="files1"
        ></v-file-input>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-list-item-title class="text-h5 mb-1">
          Balance Upload
        </v-list-item-title>
        <v-file-input
          label="Sundry Debtors"
          outlined
          dense
          v-model="files2"
        ></v-file-input>
      </v-list-item-content>
    </v-list-item>

    <v-card-actions>
      <v-btn @click="onSubmitClick()" depressed color="primary"> Submit </v-btn>
      <v-btn @click="onClear()" depressed color="primary"> Clear </v-btn>
      <v-progress-circular
        style="margin: 5px"
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  name: "HelloWorld",
  data() {
    return {
      files1: null,
      files2: null,
      isLoading: false,
    };
  },
  methods: {
    onClear() {
      this.files1 = null;
      this.files2 = null;
      this.isLoading = false;
    },
    async onSubmitClick() {
      if (this.files1 == null || this.files2 == null) {
        alert("Please add both invoices and balances.");
      } else {
        this.isLoading = true;
        const token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2NjAyMzAwNTQsImV4cCI6MTgxNzk5NjQ1NCwiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NzE2OSIsImF1ZCI6InRhbGx5U3luY0FwaSJ9.Q-au9UBiLo4ecU3H1dMbz9tUdTL0TZjj_-0zAXwuqvQ";
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        };

        const bodyParameters1 = {
          files: this.files1,
        };
        const bodyParameters2 = {
          files: this.files2,
        };

        await axios.post(
          "https://fatallyapp.azurewebsites.net/api/FATally/invoice/",
          bodyParameters1,
          config
        );

        await axios.post(
          "https://fatallyapp.azurewebsites.net/api/FATally/balance/",
          bodyParameters2,
          config
        );
        this.isLoading = false;
        this.files1 = null;
        this.files2 = null;

        alert("Success");
      }
    },
  },
};
</script>
